var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { Popover } from '@mui/material';
//
import getPosition from './getPosition';
import { StyledArrow } from './styles';
// ----------------------------------------------------------------------
export default function MenuPopover(_a) {
    var open = _a.open, children = _a.children, _b = _a.arrow, arrow = _b === void 0 ? 'top-right' : _b, disabledArrow = _a.disabledArrow, sx = _a.sx, other = __rest(_a, ["open", "children", "arrow", "disabledArrow", "sx"]);
    var _c = getPosition(arrow), style = _c.style, anchorOrigin = _c.anchorOrigin, transformOrigin = _c.transformOrigin;
    return (_jsxs(Popover, __assign({ open: Boolean(open), anchorEl: open, anchorOrigin: anchorOrigin, transformOrigin: transformOrigin, PaperProps: {
            sx: __assign(__assign(__assign({ p: 1, width: 'auto', overflow: 'inherit' }, style), { '& .MuiMenuItem-root': {
                    px: 1,
                    typography: 'body2',
                    borderRadius: 0.75,
                    '& svg': { mr: 2, width: 20, height: 20, flexShrink: 0 },
                } }), sx),
        } }, other, { children: [!disabledArrow && _jsx(StyledArrow, { arrow: arrow }), children] })));
}
